interface LogoSVGProps {
  className?: string;
}

const LogoSVG: React.FC<LogoSVGProps> = ({ className }) => {
  return (
    <svg
      width="227"
      height="240"
      viewBox="0 0 227 240"
      fill="none"
      className={className}
    >
      <path
        d="M51.2999 17.5C54.4999 14.3 58.7999 12.5 63.2999 12.5C72.6999 12.5 82.0999 12.5 91.3999 12.5C94.7999 12.5 97.8999 14.2 99.7999 17C106.7 27.4 113.7 37.8 120.6 48.3C122.5 51.1 125.6 52.8 129 52.8C142.8 52.8 156.7 52.8 170.5 52.8C176.1 52.8 180.6 57.3 180.6 62.9C180.6 96.4 180.6 129.9 180.6 163.5C180.6 167.9 178.8 172.2 175.7 175.4C172.6 178.6 168.3 180.3 163.8 180.3C130.3 180.3 96.7999 180.3 63.1999 180.3C58.7999 180.3 54.4999 178.5 51.2999 175.4C48.1999 172.3 46.3999 168 46.3999 163.5C46.3999 118.8 46.3999 74.1 46.3999 29.4C46.4999 24.9 48.1999 20.7 51.2999 17.5Z"
        fill="url(#paint0_linear_405_1930)"
      />
      <path
        d="M173.8 69.5C173.8 65.8 170.8 62.8 167.1 62.8C154.4 62.8 141.6 62.8 128.9 62.8C122.2 62.8 115.9 59.4 112.2 53.8C105 43.3 97.8002 32.7001 90.7002 22.2001C89.5002 20.4001 87.4002 19.3 85.2002 19.3C77.9002 19.3 70.6002 19.3 63.3002 19.3C57.7002 19.3 53.2002 23.8 53.2002 29.4C53.2002 74.1 53.2002 118.8 53.2002 163.5C53.2002 169.1 57.7002 173.6 63.3002 173.6C96.8002 173.6 130.3 173.6 163.9 173.6C169.5 173.6 174 169.1 174 163.5C173.8 132.1 173.8 100.8 173.8 69.5Z"
        fill="#2C0735"
      />
      <path
        d="M118.1 127.9C114.2 127.9 110.6 130.1 108.4 133.9C104.1 141.4 99.6998 148.9 95.3998 156.5C95.0998 157 94.5998 157.2 94.0998 157C93.5998 156.8 93.2998 156.3 93.2998 155.7C93.2998 137.8 93.3998 119.8 93.3998 101.9C93.3998 101.2 93.8998 100.6 94.5998 100.6C115.6 100.7 136.6 100.7 157.5 100.8C158.6 113.2 153.9 122.1 143.4 127.5C142.9 127.8 142.4 127.9 141.8 127.9C134 127.9 126.1 127.9 118.1 127.9Z"
        fill="url(#paint1_linear_405_1930)"
      />
      <path
        d="M1.2998 197.2H19.6998V201.7H6.7998V209.5H18.6998V214H6.7998V226.2H1.2998V197.2Z"
        fill="white"
      />
      <path
        d="M30.9997 199.3C30.9997 200.9 29.7997 202.2 27.7997 202.2C25.8997 202.2 24.6997 200.9 24.6997 199.3C24.6997 197.7 25.8997 196.4 27.7997 196.4C29.6997 196.4 30.9997 197.6 30.9997 199.3ZM25.0997 226.2V205.3H30.5997V226.2H25.0997Z"
        fill="white"
      />
      <path d="M36.7998 195.4H42.2998V226.2H36.7998V195.4Z" fill="white" />
      <path
        d="M52.2996 217.3C52.4996 220.8 55.6996 222.6 59.3996 222.6C62.0996 222.6 63.9996 222.2 65.8996 221.6L66.6996 225.4C64.6996 226.2 62.0996 226.8 58.6996 226.8C51.3996 226.8 47.0996 222.5 47.0996 216.1C47.0996 210.7 50.6996 204.8 58.1996 204.8C65.7996 204.8 67.9996 210.9 67.9996 215.2C67.9996 216.1 67.8996 216.9 67.7996 217.3H52.2996ZM62.6996 213.4C62.6996 211.9 61.9996 208.4 57.7996 208.4C54.0996 208.4 52.5996 211.4 52.2996 213.4H62.6996Z"
        fill="white"
      />
      <path
        d="M72.1997 221.1C73.4997 221.9 76.0997 222.8 78.0997 222.8C80.6997 222.8 81.6997 221.8 81.6997 220.5C81.6997 219 80.7997 218.4 77.9997 217.6C73.5997 216.3 71.6997 214 71.6997 211.4C71.6997 207.7 74.8997 204.8 80.1997 204.8C82.6997 204.8 84.9997 205.4 86.2997 206.1L85.1997 209.9C84.2997 209.4 82.2997 208.6 80.2997 208.6C78.2997 208.6 77.1997 209.5 77.1997 210.8C77.1997 212.2 78.3997 212.8 81.1997 213.7C85.3997 214.9 87.1997 216.8 87.1997 220.1C87.1997 223.8 84.1997 226.7 78.1997 226.7C75.3997 226.7 72.8997 226 71.1997 225.1L72.1997 221.1Z"
        fill="white"
      />
      <path
        d="M101.7 197.2H120.1V201.7H107.3V209.5H119.2V214H107.3V226.2H101.8L101.7 197.2Z"
        fill="white"
      />
      <path
        d="M125.4 195.4H130.9V226.2H125.4C125.3 226.2 125.3 195.4 125.4 195.4Z"
        fill="white"
      />
      <path
        d="M157.5 219.9C157.5 222.5 157.6 224.6 157.7 226.2H152.9L152.6 222.7H152.5C151.6 224.2 149.4 226.7 145.1 226.7C141.3 226.7 137.2 224.6 137.2 217.3V205.2H142.7V216.3C142.7 219.9 144 222.2 147.1 222.2C149.5 222.2 151.1 220.6 151.7 219.1C151.9 218.7 152 218.1 152 217.5V205.2H157.5V219.9Z"
        fill="white"
      />
      <path
        d="M167.4 217.3C167.6 220.8 170.8 222.6 174.5 222.6C177.2 222.6 179.1 222.2 181 221.6L181.8 225.4C179.8 226.2 177.2 226.8 173.8 226.8C166.5 226.8 162.2 222.5 162.2 216.1C162.2 210.7 165.8 204.8 173.3 204.8C180.9 204.8 183.1 210.9 183.1 215.2C183.1 216.1 183 216.9 182.9 217.3H167.4ZM177.8 213.4C177.8 211.9 177.1 208.4 172.9 208.4C169.2 208.4 167.7 211.4 167.4 213.4H177.8Z"
        fill="white"
      />
      <path
        d="M187.6 211.6C187.6 209.2 187.5 207.1 187.4 205.3H192.2L192.5 208.7H192.6C193.5 207.1 195.9 204.8 199.8 204.8C203.5 204.8 207.9 207 207.9 214.2V226.2H202.4V214.9C202.4 211.6 201.1 209.2 197.9 209.2C195.5 209.2 193.9 210.9 193.3 212.5C193.1 212.9 193.1 213.6 193.1 214.1V226.2H187.6V211.6Z"
        fill="white"
      />
      <path
        d="M220.4 199.1V205.2H225.8V209.3H220.4V218C220.4 220.6 221 222.1 223.3 222.1C224.3 222.1 225.1 222 225.6 221.8L225.7 226C224.9 226.3 223.4 226.6 221.6 226.6C219.5 226.6 217.8 225.8 216.8 224.7C215.6 223.4 215.1 221.4 215.1 218.8V209.3H212V205.2H215.1V200.4L220.4 199.1Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_405_1930"
          x1="113.5"
          y1="180.132"
          x2="113.5"
          y2="12.5301"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4B409A" />
          <stop offset="1" stopColor="#F55D38" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_405_1930"
          x1="125.478"
          y1="156.988"
          x2="125.478"
          y2="100.511"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5B5BAA" />
          <stop offset="0.4018" stopColor="#4B409A" />
          <stop offset="1" stopColor="#F55D38" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default LogoSVG;
