import React from "react";
import { PolarArea } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
  ChartOptions,
  TooltipItem,
} from "chart.js";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

interface PolarAreaChartProps {
  data: ChartData<"polarArea">;
  options?: ChartOptions<"polarArea">;
}

const defaultOptions: ChartOptions<"polarArea"> = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        padding: 20,
        font: {
          size: 14,
        },
        color: "#4B5563",
      },
    },
    tooltip: {
      backgroundColor: "#FFFFFF",
      bodyColor: "#1F2937",
      borderColor: "#E5E7EB",
      borderWidth: 1,
      cornerRadius: 5,
      padding: 10,
      titleColor: "#111827",
      titleFont: {
        size: 14,
      },
      bodyFont: {
        size: 12,
      },
      callbacks: {
        title: (tooltipItems: TooltipItem<"polarArea">[]) =>
          `Asignaciones de ${tooltipItems[0]?.label || ""}`,
        label: (tooltipItem: TooltipItem<"polarArea">) =>
          `${tooltipItem.dataset.label || ""}: ${tooltipItem.raw as number} facturas`,
      },
    },
  },
};

const PolarAreaChart: React.FC<PolarAreaChartProps> = ({ data, options }) => {
  const mergedOptions: ChartOptions<"polarArea"> = {
    ...defaultOptions,
    ...options,
    plugins: {
      ...defaultOptions.plugins,
      ...options?.plugins,
      legend: {
        ...defaultOptions.plugins?.legend,
        ...options?.plugins?.legend,
      },
      tooltip: {
        ...defaultOptions.plugins?.tooltip,
        ...options?.plugins?.tooltip,
        callbacks: {
          ...defaultOptions.plugins?.tooltip?.callbacks,
          ...options?.plugins?.tooltip?.callbacks,
        },
      },
    },
  };

  return <PolarArea data={data} options={mergedOptions} />;
};

export default PolarAreaChart;
