import React, { useCallback, useEffect, useState } from "react";
import TaskCard from "../components/TaskCard";
import TaskColumn from "../components/TaskColumn";
import { ModalAssignments } from "../components/ModalAssigments";
import {
  Assignments,
  TaskStatus,
} from "../../../../../../features/assignments/types";
import NoResultFound from "../../../../../../_emailApp/layout/components/NoResultFound";
import IconCheckmarkSVG from "../../../../../../_emailApp/assets/svg/IconCheckmarkSVG";
import { fetchBoard, saveProgressState } from "../service/apiService";
import { STATUS_DESCRIPTIONS } from "../../../../../constanst/constants";
import { useDispatch } from "react-redux";
import { setError } from "../../../../../../features/errors/errorSlice";
import { useRoles } from "../../../../../hooks/useRoles";

interface TaskBoardProps {
  userId: string;
}

const EmailBoard: React.FC<TaskBoardProps> = ({ userId }) => {
  const dispatch = useDispatch();

  const [tasks, setTasks] = useState<Assignments[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedTask, setSelectedTask] = useState<Assignments | null>(null);
  const [noTasks, setNoTasks] = useState<boolean>(false);

  const { getCurrentUserRole } = useRoles();
  const currentUserRole = getCurrentUserRole();
  const role = currentUserRole?.roleName;

  const fetchTasks = useCallback(async () => {
    try {
      const isUserRole = role === "User";
      const data = await fetchBoard(userId, isUserRole);
      const filteredData = data.filter((task: any) => task.manually === false);
      if (filteredData.length === 0) {
        setNoTasks(true);
      } else {
        setNoTasks(false);
        setTasks(filteredData);
      }
    } catch (error) {
      setNoTasks(true);
      return;
    }
  }, [userId, setTasks, setNoTasks]);

  const handleSave = () => {
    fetchTasks();
  };

  const handleSaveProgressState = async (
    taskId: string,
    status: TaskStatus
  ) => {
    try {
      await saveProgressState(taskId, status);
      dispatch(
        setError({
          errorCode: 200,
          errorMessage: "El progreso de la tarea fue actualizado con éxito",
        })
      );

      fetchTasks();
    } catch (error) {
      dispatch(
        setError({
          errorCode: 500,
          errorMessage:
            "Error al actualizar el progreso de la tarea. Inténtalo de nuevo.",
        })
      );
    }
  };

  const handleModalOpen = (task: Assignments) => {
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setSelectedTask(null);
  };

  const handleDragStart = (
    e: React.DragEvent<HTMLDivElement>,
    task: Assignments
  ) => {
    e.dataTransfer.setData("taskId", task.assignationId || "");
  };

  const handleDrop =
    (status: TaskStatus) => async (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const taskId = e.dataTransfer.getData("taskId");

      const dataTask = tasks.find(
        (item) => item.assignationId === taskId
      ) as Assignments;

      if (status === "REVISION" || status === "DONE") {
        handleModalOpen(dataTask);
      } else {
        await handleSaveProgressState(taskId, status);
      }
    };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  useEffect(() => {
    fetchTasks();
  }, [fetchTasks]);

  return (
    <div>
      {noTasks ? (
        <NoResultFound />
      ) : (
        <div className="flex flex-row justify-between gap-5 overflow-x-auto md:overflow-x-visible">
          {Object.entries(STATUS_DESCRIPTIONS).map(([status, title]) => {
            const filteredTasks =
              tasks?.filter((task) => task?.processStage === status) || [];

            return (
              <TaskColumn
                key={status}
                titleColumn={
                  <div className="flex justify-between items-center w-full">
                    <span>{title}</span>
                    <div className="flex">
                      {status === "DONE" && (
                        <IconCheckmarkSVG width={20} className="mr-3" />
                      )}
                      <span className="text-sm">{filteredTasks.length}</span>
                    </div>
                  </div>
                }
                onDrop={handleDrop(status as TaskStatus)}
                onDragOver={handleDragOver}
              >
                {filteredTasks.map((task) => (
                  <TaskCard
                    key={task.assignationCode}
                    onDragStart={(e) => handleDragStart(e, task)}
                    onClick={() => handleModalOpen(task)}
                    {...task}
                  />
                ))}
              </TaskColumn>
            );
          })}
        </div>
      )}
      {isModalOpen && selectedTask && (
        <ModalAssignments
          isOpen={isModalOpen}
          onClose={handleModalClose}
          onSave={handleSave}
          {...selectedTask}
        />
      )}
    </div>
  );
};

export default EmailBoard;
