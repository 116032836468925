import React from "react";

const LoadingSpinner: React.FC = () => {
  return (
    <div className="flex justify-center  min-h-screen p-12">
      <div className="w-12 h-12 rounded-full absolute border-4 border-solid border-primary-200"></div>
      <div className="w-12 h-12 rounded-full animate-spin absolute border-4 border-solid border-primary border-t-transparent"></div>
    </div>
  );
};

export default LoadingSpinner;
