import { Link, useLocation, useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Typography from "../../../../_emailApp/layout/components/Typography";
import TextField from "../../../../_emailApp/layout/components/TextField";
import Button from "../../../../_emailApp/layout/components/Button";
import { BASE_API_URL } from "../../../constanst/constantURL";
import { fetchAPI } from "../../../api/fetchAPI";
import { setError } from "../../../../features/errors/errorSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

interface NewPasswordFormValues {
  newPassword: string;
  confirmPassword: string;
}

const newPasswordSchema = Yup.object({
  newPassword: Yup.string()
    .min(3, "Mínimo 3 caracteres")
    .max(50, "Máximo 50 caracteres")
    .required("La nueva contraseña es obligatoria"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Las contraseñas no coinciden")
    .min(3, "Mínimo 3 caracteres")
    .max(50, "Máximo 50 caracteres")
    .required("Debes confirmar la nueva contraseña"),
});

export function NewPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { email, code } = location.state || {};

  const handleSubmit = async (values: NewPasswordFormValues) => {
    const url = `${BASE_API_URL}/public/auth/passwordChangeRequest`;

    const requestBody = {
      emailAddress: email,
      changeCode: code,
      newPassword: values.newPassword,
    };

    try {
      const response = await fetchAPI({
        url: url,
        method: "POST",
        data: requestBody,
      });

      if (
        response.status === 200 ||
        response.status === 202 ||
        response.status === 204
      ) {
        dispatch(
          setError({
            errorCode: 200,
            errorMessage: "La contraseña se ha restablecido correctamente",
          })
        );
        navigateToLogin();
      } else {
        throw new Error("Error en la respuesta");
      }
    } catch (error) {
      dispatch(
        setError({
          errorCode: 500,
          errorMessage: "Hubo un problema al restablecer la contraseña",
        })
      );
    }
  };

  const navigateToLogin = () => {
    navigate("/auth/login");
  };

  return (
    <div className="flex flex-col justify-center md:justify-start my-auto pt-8 md:pt-0 px-8 md:px-24 lg:px-32">
      <Typography
        as="h1"
        fontSize="text-md"
        textAlign="center"
        fontWeight="500"
      >
        Ingrese la nueva contraseña
      </Typography>
      <Typography
        as="h2"
        fontSize="text-base"
        textAlign="center"
        fontWeight="400"
        className="mt-6 mb-10"
      >
        Tu nueva contraseña debe ser diferente a la contraseña anterior
      </Typography>
      <Formik
        initialValues={{ newPassword: "", confirmPassword: "" }}
        validationSchema={newPasswordSchema}
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <Form className="flex flex-col pt-3 md:pt-8">
            <div className="mb-4">
              <TextField
                label="Nueva contraseña"
                type="password"
                name="newPassword"
                placeholder="Ingresa la nueva contraseña"
              />
            </div>
            <div className="mb-4">
              <TextField
                label="Confirmar contraseña"
                type="password"
                name="confirmPassword"
                placeholder="Repite la nueva contraseña"
              />
            </div>
            <div className="flex justify-end">
              <Typography
                as="span"
                fontSize="text-sm"
                fontWeight="700"
                textAlign="right"
                className="mt-4"
              >
                <Link to={"/auth/login"}>{t("auth.RETURN_TO_LOG")}</Link>
              </Typography>
            </div>
            <div className="flex justify-center mt-12">
              <Button
                type="submit"
                color="primary"
                size="medium"
                width={300}
                disabled={!isValid}
              >
                Restablecer la contraseña
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
