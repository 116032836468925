export const invoiceData = [
  { state: "Recibido", count: 20 },
  { state: "En revisión", count: 15 },
  { state: "En proceso de pago", count: 10 },
  { state: "Finalizado", count: 25 },
];

export const finalizedData = [
  { status: "Aprobado", count: 10 },
  { status: "Rechazado", count: 5 },
  { status: "Pagado", count: 7 },
  { status: "Cancelado", count: 2 },
  { status: "Archivado", count: 1 },
];

export const monthlyInvoiceData = [
  { month: "Enero", received: 15, reviewed: 25, inProcess: 10, finalized: 5 },
  {
    month: "Febrero",
    received: 60,
    reviewed: 35,
    inProcess: 15,
    finalized: 10,
  },
  { month: "Marzo", received: 10, reviewed: 30, inProcess: 40, finalized: 28 },
  { month: "Abril", received: 30, reviewed: 45, inProcess: 30, finalized: 20 },
  { month: "Mayo", received: 55, reviewed: 60, inProcess: 30, finalized: 25 },
  { month: "Junio", received: 15, reviewed: 55, inProcess: 10, finalized: 30 },
];

export const monthlyInvoice = [
  { month: "Enero", received: 30, reviewed: 25, inProcess: 10, finalized: 5 },
  {
    month: "Febrero",
    received: 40,
    reviewed: 35,
    inProcess: 15,
    finalized: 10,
  },
  { month: "Marzo", received: 45, reviewed: 40, inProcess: 20, finalized: 15 },
  { month: "Abril", received: 50, reviewed: 45, inProcess: 25, finalized: 20 },
  { month: "Mayo", received: 55, reviewed: 50, inProcess: 30, finalized: 25 },
  { month: "Junio", received: 60, reviewed: 55, inProcess: 35, finalized: 30 },
];

export const pendingInvoices = [
  {
    radicado: "INV-001",
    proveedor: "Proveedor A",
    fechaVencimiento: "2024-11-01",
    montoTotal: 1200,
  },
  {
    radicado: "INV-002",
    proveedor: "Proveedor B",
    fechaVencimiento: "2024-11-15",
    montoTotal: 2300,
  },
  {
    radicado: "INV-003",
    proveedor: "Proveedor C",
    fechaVencimiento: "2024-11-20",
    montoTotal: 800,
  },
];

export const providerInvoiceData = [
  { provider: "Proveedor A", total: 5000 },
  { provider: "Proveedor B", total: 6000 },
  { provider: "Proveedor C", total: 5550 },
  { provider: "Proveedor D", total: 7000 },
];

export const totalByProvider = [
  { name: "Proveedor A", total: 23000000 },
  { name: "Proveedor B", total: 40000000 },
  { name: "Proveedor C", total: 30000000 },
  { name: "Proveedor D", total: 35000000 },
  { name: "Proveedor E", total: 55000000 },
  { name: "Proveedor F", total: 45000000 },
];

export const monthlyTotalAmounts = [
  { month: "Enero", total: 23000000 },
  { month: "Febrero", total: 40000000 },
  { month: "Marzo", total: 30000000 },
  { month: "Abril", total: 35000000 },
  { month: "Mayo", total: 55000000 },
  { month: "Junio", total: 45000000 },
];

export const totalAmountsByProcess = [
  {
    name: "Proveedor A",
    received: 15000000,
    reviewed: 25000000,
    inProcess: 10000000,
    finalized: 5000000,
  },
  {
    name: "Proveedor B",
    received: 60000000,
    reviewed: 35000000,
    inProcess: 15000000,
    finalized: 10000000,
  },
  {
    name: "Proveedor C",
    received: 10000000,
    reviewed: 30000000,
    inProcess: 40000000,
    finalized: 28000000,
  },
  {
    name: "Proveedor D",
    received: 30000000,
    reviewed: 45000000,
    inProcess: 30000000,
    finalized: 20000000,
  },
  {
    name: "Proveedor F",
    received: 55000000,
    reviewed: 60000000,
    inProcess: 30000000,
    finalized: 25000000,
  },
  {
    name: "Proveedor G",
    received: 15000000,
    reviewed: 55000000,
    inProcess: 10000000,
    finalized: 30000000,
  },
];

export const monthlyProcessTotals = [
  {
    month: "Enero",
    received: 15000000,
    reviewed: 25000000,
    inProcess: 10000000,
    finalized: 5000000,
  },
  {
    month: "Febrero",
    received: 60000000,
    reviewed: 35000000,
    inProcess: 15000000,
    finalized: 10000000,
  },
  {
    month: "Marzo",
    received: 10000000,
    reviewed: 30000000,
    inProcess: 40000000,
    finalized: 28000000,
  },
  {
    month: "Abril",
    received: 30000000,
    reviewed: 45000000,
    inProcess: 30000000,
    finalized: 20000000,
  },
  {
    month: "Mayo",
    received: 55000000,
    reviewed: 60000000,
    inProcess: 30000000,
    finalized: 25000000,
  },
  {
    month: "Junio",
    received: 15000000,
    reviewed: 55000000,
    inProcess: 10000000,
    finalized: 30000000,
  },
];

export const totalByProject = [
  { name: "Proyecto A", total: 23000000 },
  { name: "Proyecto B", total: 40000000 },
  { name: "Proyecto C", total: 30000000 },
  { name: "Proyecto D", total: 35000000 },
  { name: "Proyecto E", total: 55000000 },
  { name: "Proyecto F", total: 45000000 },
];

export const monthlyProjectTotals = [
  { month: "Enero", total: 23000000 },
  { month: "Febrero", total: 40000000 },
  { month: "Marzo", total: 30000000 },
  { month: "Abril", total: 35000000 },
  { month: "Mayo", total: 55000000 },
  { month: "Junio", total: 45000000 },
];

export const totalAmountsByProject = [
  {
    name: "Proyecto A",
    received: 15000000,
    reviewed: 25000000,
    inProcess: 10000000,
    finalized: 5000000,
  },
  {
    name: "Proyecto B",
    received: 60000000,
    reviewed: 35000000,
    inProcess: 15000000,
    finalized: 10000000,
  },
  {
    name: "Proyecto C",
    received: 10000000,
    reviewed: 30000000,
    inProcess: 40000000,
    finalized: 28000000,
  },
  {
    name: "Proyecto D",
    received: 30000000,
    reviewed: 45000000,
    inProcess: 30000000,
    finalized: 20000000,
  },
  {
    name: "Proyecto F",
    received: 55000000,
    reviewed: 60000000,
    inProcess: 30000000,
    finalized: 25000000,
  },
  {
    name: "Proyecto G",
    received: 15000000,
    reviewed: 55000000,
    inProcess: 10000000,
    finalized: 30000000,
  },
];

export const monthlyProcessTotalsByProject = [
  {
    month: "Enero",
    received: 15000000,
    reviewed: 25000000,
    inProcess: 10000000,
    finalized: 5000000,
  },
  {
    month: "Febrero",
    received: 60000000,
    reviewed: 35000000,
    inProcess: 15000000,
    finalized: 10000000,
  },
  {
    month: "Marzo",
    received: 10000000,
    reviewed: 30000000,
    inProcess: 40000000,
    finalized: 28000000,
  },
  {
    month: "Abril",
    received: 30000000,
    reviewed: 45000000,
    inProcess: 30000000,
    finalized: 20000000,
  },
  {
    month: "Mayo",
    received: 55000000,
    reviewed: 60000000,
    inProcess: 30000000,
    finalized: 25000000,
  },
  {
    month: "Junio",
    received: 15000000,
    reviewed: 55000000,
    inProcess: 10000000,
    finalized: 30000000,
  },
];
