import { ErrorMessage, Field } from "formik";
import React from "react";

interface TextFieldProps {
  type?: string;
  label?: string;
  placeholder?: string;
  name: string;
  className?: string;
  requested?: boolean;
}

const TextField: React.FC<TextFieldProps> = ({
  type,
  label,
  placeholder,
  name,
  className,
  requested,
}) => {
  const isTextarea = type === "textarea";
  return (
    <div className={className}>
      {label && (
        <label
          className="font-semibold text-sm text-label-primary"
          htmlFor={name}
        >
          {label}
          {requested && (
            <span className="font-semibold text-sm text-danger pl-1">*</span>
          )}
        </label>
      )}

      <Field
        placeholder={placeholder}
        className="mt-1 focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block w-full appearance-none rounded-lg border border-solid border-background bg-background bg-clip-padding px-2 py-2 font-medium text-label-secundary transition-all focus:border-primary focus:outline-none focus:transition-shadow focus:bg-primary-100"
        type={isTextarea ? undefined : type}
        name={name}
        as={isTextarea ? "textarea" : "input"}
        rows={isTextarea ? 4 : undefined}
      />
      <ErrorMessage
        name={name}
        component="div"
        className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-3"
      />
    </div>
  );
};

export default TextField;
