import React, { useEffect, useRef, useState } from "react";
import DoughnutChart from "../../../../../../../_emailApp/layout/components/DoughnutChart";
import BarChart from "../../../../../../../_emailApp/layout/components/BarChart";
import LineChart from "../../../../../../../_emailApp/layout/components/LineChart";
import { invoiceData, monthlyInvoiceData } from "../data";
import Button from "../../../../../../../_emailApp/layout/components/Button";
import { IoFilterOutline } from "react-icons/io5";
import FilterModalReports from "./FilterModalReports";
import { Form, Formik } from "formik";
import TextField from "../../../../../../../_emailApp/layout/components/TextField";

const ReportOptions = [
  {
    label: "Reportes por proyecto",
    value: "true",
  },
  {
    label: "Reportes por proveedor",
    value: "falso",
  },
];

const initialValues = {
  startDate: "",
  endDate: "",
};

const ProgressCharts: React.FC = () => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectTypeReport, setSelectTypeReport] = useState<string | null>(null);

  const hasFilters = selectTypeReport !== null && selectTypeReport !== "";

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setIsModalOpen(false);
    }
  };

  const handleFiltersSubmit = (values: { selectTypeReport: string }) => {
    setSelectTypeReport(values.selectTypeReport);
  };

  const handleClearFilters = () => {
    setSelectTypeReport(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const doughnutData = {
    labels: invoiceData.map((item) => item.state),
    datasets: [
      {
        data: invoiceData.map((item) => item.count),
        backgroundColor: ["#2691F5", "#FFA800", "#F64E60", "#11B9B2"],
        borderWidth: 2,
      },
    ],
  };

  const barData = {
    labels: ["Facturas"],
    datasets: [
      {
        label: "Aprobado",
        data: [10],
        backgroundColor: "#11B9B2",
      },
      {
        label: "Rechazado",
        data: [5],
        backgroundColor: "#F64E60",
      },
      {
        label: "Pagado",
        data: [15],
        backgroundColor: "#2691F5",
      },
      {
        label: "Cancelado",
        data: [3],
        backgroundColor: "#FFA800",
      },
      {
        label: "Archivado",
        data: [2],
        backgroundColor: "#999999",
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: true,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  const lineData = {
    labels: monthlyInvoiceData.map((data) => data.month),
    datasets: [
      {
        label: "Recibidas",
        data: monthlyInvoiceData.map((data) => data.received),
        borderColor: "#2691F5",
        backgroundColor: "#2691F5",
        fill: false,
        tension: 0.4,
        pointRadius: 2,
        borderWidth: 2,
      },
      {
        label: "Revisadas",
        data: monthlyInvoiceData.map((data) => data.reviewed),
        borderColor: "#FFA800",
        backgroundColor: "#FFA800",
        fill: false,
        tension: 0.4,
        pointRadius: 2,
        borderWidth: 2,
      },
      {
        label: "En Proceso",
        data: monthlyInvoiceData.map((data) => data.inProcess),
        borderColor: "#F64E60",
        backgroundColor: "#F64E60",
        fill: false,
        tension: 0.4,
        pointRadius: 2,
        borderWidth: 2,
      },
      {
        label: "Finalizadas",
        data: monthlyInvoiceData.map((data) => data.finalized),
        borderColor: "#11B9B2",
        backgroundColor: "#11B9B2",
        fill: false,
        tension: 0.4,
        pointRadius: 2,
        borderWidth: 2,
      },
    ],
  };

  return (
    <div>
      <div className="flex mb-4" ref={modalRef}>
        <div className="flex flex-col xl:flex-row items-start xl:items-center w-full bg-white shadow-md rounded-lg p-6 border border-solid border-menu-button">
          <div>
            <div className="flex relative items-center">
              <Button
                onClick={() => setIsModalOpen(!isModalOpen)}
                type="submit"
                color={hasFilters ? "primary" : "cancel"}
                size="medium"
                width={100}
                startIcon={<IoFilterOutline size="1.5em" />}
              >
                Filtros
              </Button>
            </div>
            {isModalOpen && (
              <FilterModalReports
                onSubmit={handleFiltersSubmit}
                reportOptions={ReportOptions}
                setIsModalOpen={setIsModalOpen}
                initialValues={{
                  selectTypeReport: selectTypeReport || "",
                }}
                onClearFilters={handleClearFilters}
              />
            )}
          </div>
          <div className="xl:ml-[30px] mt-4 xl:mt-0">
            <Formik
              initialValues={initialValues}
              onSubmit={(values) => {
                console.log(values);
              }}
            >
              {({ values }) => (
                <Form>
                  <div className="flex">
                    <TextField
                      name="startDate"
                      placeholder="Fecha inicio"
                      type="date"
                      requested
                    />
                    <div className="ml-[30px]">
                      <TextField
                        name="endDate"
                        placeholder="Fecha fin"
                        type="date"
                        requested
                      />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white shadow-md rounded-lg p-6 border border-solid border-menu-button">
          <h2 className="text-md font-semibold mb-4">
            Distribución de Facturas por Proceso
          </h2>
          <DoughnutChart data={doughnutData} />
        </div>
        <div className="bg-white shadow-md rounded-lg p-6 border border-solid border-menu-button">
          <h2 className="text-md font-semibold mb-4">
            Estados de Facturas Finalizadas
          </h2>
          <BarChart data={barData} options={chartOptions} />
        </div>
        <div className="bg-white shadow-md rounded-lg p-6 lg:col-span-2 border border-solid border-menu-button">
          <h2 className="text-md font-semibold mb-4">
            Facturas procesadas por Mes
          </h2>
          <LineChart data={lineData} />
        </div>
      </div>
    </div>
  );
};

export default ProgressCharts;
