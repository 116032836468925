import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

interface BarChartProps {
  data: ChartData<"bar">;
  options?: ChartOptions<"bar">;
}

const defaultOptions: ChartOptions<"bar"> = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 1.5,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        padding: 20,
        font: {
          size: 14,
        },
        color: "#4B5563",
      },
    },
    tooltip: {
      backgroundColor: "#FFFFFF",
      bodyColor: "#1F2937",
      borderColor: "#E5E7EB",
      borderWidth: 1,
      cornerRadius: 5,
      padding: 10,
      titleColor: "#111827",
      titleFont: {
        size: 14,
      },
      bodyFont: {
        size: 12,
      },
    },
  },
  elements: {
    bar: {
      borderRadius: 5,
      borderSkipped: "start",
    },
  },
  animation: {
    duration: 1000,
    easing: "easeInOutQuad",
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: "#6B7280",
        font: {
          size: 12,
        },
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        color: "#6B7280",
        font: {
          size: 12,
        },
        stepSize: 1,
      },
    },
  },
};

const BarChart: React.FC<BarChartProps> = ({ data, options }) => {
  const mergedOptions: ChartOptions<"bar"> = {
    ...defaultOptions,
    ...options,
    plugins: {
      ...defaultOptions.plugins,
      ...options?.plugins,
      legend: {
        ...defaultOptions.plugins?.legend,
        ...options?.plugins?.legend,
      },
      tooltip: {
        ...defaultOptions.plugins?.tooltip,
        ...options?.plugins?.tooltip,
      },
    },
  };

  return <Bar data={data} options={mergedOptions} />;
};

export default BarChart;
