import React, { useCallback, useEffect, useRef, useState } from "react";
import Pagination from "../../../../../_emailApp/layout/components/Pagination";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { fetchAPI } from "../../../../api/fetchAPI";
import { formatDate } from "../../../../utils/transformDate";
import {
  STATUS_DESCRIPTIONS,
  TOTAL_PAGES,
} from "../../../../constanst/constants";
import NoResultFound from "../../../../../_emailApp/layout/components/NoResultFound";
import Button from "../../../../../_emailApp/layout/components/Button";
import { IoAddOutline, IoFilterOutline } from "react-icons/io5";
import { NavbarDefault } from "../../../../../_emailApp/layout/components/navbar/NavbarDefault";
import { Assignments } from "../../../../../features/assignments/types";
import FilterModalAssignment from "./components/FilterModalAssignment";
import { truncateText } from "../../../../utils/truncateText";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { Department } from "../../../../../features/departments/types";
import { findDepartmentLabels } from "../../../../utils/findDepartmentLabels";
import LoadingSpinner from "../../../../../_emailApp/layout/components/LoadingSpinner";
import { BASE_API_URL } from "../../../../constanst/constantURL";

interface HeaderColumnProps {
  label?: string;
  className?: string;
}

const HeaderColumn: React.FC<HeaderColumnProps> = ({ label }) => {
  return (
    <th
      className={`px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-sm border-b-solid tracking-none whitespace-nowrap opacity-70`}
    >
      {label}
    </th>
  );
};

const TableAssignments: React.FC = () => {
  const navigate = useNavigate();
  const assignmentOptions = [
    {
      label: "Asignaciones Manuales",
      value: "true",
    },
    {
      label: "Asignaciones Email",
      value: "falso",
    },
  ];
  const modalRef = useRef<HTMLDivElement>(null);

  const [assignments, setAssignments] = useState<Assignments[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sizePages, setSizePages] = useState<number>(TOTAL_PAGES);
  const [noResultFound, setNoResultFound] = useState<boolean>(false);
  const [selectTypeAssignment, setSelectTypeAssignment] = useState<
    string | null
  >(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const departments = useSelector(
    (state: RootState) => state.departments.departments as Department[]
  );

  const hasFilters =
    selectTypeAssignment !== null && selectTypeAssignment !== "";

  const fetchAssignments = useCallback(
    async (page: number) => {
      setNoResultFound(false);
      setLoading(true);
      try {
        const response = await fetchAPI<any>({
          url: `${BASE_API_URL}/assignation/page/${page - 1}/size/${sizePages}`,
          method: "POST",
          data: {
            filters: selectTypeAssignment
              ? { manually: selectTypeAssignment === "true" }
              : null,
          },
        });

        if (response.data?.content.length !== 0) {
          setAssignments(response.data?.content);
          setTotalPages(response.data?.totalPages);
          setSizePages(response.data?.size);
        } else {
          setNoResultFound(true);
        }
      } catch (error) {
        return;
      } finally {
        setLoading(false);
      }
    },
    [sizePages, selectTypeAssignment]
  );

  const handleNewAssignment = () => {
    navigate(
      "/dashboard/assignments/table-assignments/select-type-assignment",
      {
        state: { isResponseDocument: false },
      }
    );
  };

  const handleFiltersSubmit = (values: { selectTypeAssignment: string }) => {
    setSelectTypeAssignment(values.selectTypeAssignment);
  };

  const handleClearFilters = () => {
    setSelectTypeAssignment(null);
    setIsModalOpen(false);
  };

  const getAssignmentProcessStage = (stage: string): string => {
    return STATUS_DESCRIPTIONS[stage as keyof typeof STATUS_DESCRIPTIONS];
  };

  const getClassByState = (state: string): string => {
    const numericState = Number(state);

    if (numericState < 0) {
      return "bg-danger-200 text-danger";
    } else if (numericState > 5) {
      return "bg-info-200 text-info";
    } else if (numericState >= 1 && numericState <= 5) {
      return "bg-warning-200 text-warning";
    } else {
      return "bg-success-200 text-success";
    }
  };

  const getClassByProcessStage = (processStage: string): string => {
    if (processStage === "DONE") {
      return "text-success font-bold";
    }

    return "";
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchAssignments(currentPage);
  }, [currentPage, fetchAssignments]);

  return (
    <>
      <div className="p-4 sm:ml-64 mt-14">
        <NavbarDefault title="Asignaciones" />
        <div className="flex justify-end mb-8">
          <div className="ml-0 md:ml-6 mb-4 md:mb-0" ref={modalRef}>
            <div className="relative inline-block">
              <Button
                onClick={() => setIsModalOpen(!isModalOpen)}
                type="submit"
                className="md:ml-6"
                color={hasFilters ? "primary" : "cancel"}
                size="medium"
                width={100}
                startIcon={<IoFilterOutline size="1.5em" />}
              >
                Filtros {hasFilters}
              </Button>
            </div>
            {isModalOpen && (
              <FilterModalAssignment
                onSubmit={handleFiltersSubmit}
                assignmentOptions={assignmentOptions}
                setIsModalOpen={setIsModalOpen}
                initialValues={{
                  selectTypeAssignment: selectTypeAssignment || "",
                }}
                onClearFilters={handleClearFilters}
              />
            )}
          </div>
          <Button
            onClick={handleNewAssignment}
            type="submit"
            className="ml-6"
            color="primary"
            size="medium"
            width={200}
            startIcon={<IoAddOutline size="1.5em" />}
          >
            Nueva asignación
          </Button>
        </div>

        {loading ? (
          <div className="flex justify-center py-10">
            <LoadingSpinner />
          </div>
        ) : (
          <div className="overflow-x-auto">
            {noResultFound ? (
              <div>
                <NoResultFound />
              </div>
            ) : (
              <table className="min-w-full">
                <thead className="align-bottom">
                  <tr>
                    <HeaderColumn label="Código" />
                    <HeaderColumn label="Fecha Envío" />
                    <HeaderColumn label="Fecha Límite" />
                    <HeaderColumn label="Creado Por" />
                    <HeaderColumn label="Asunto" />
                    <HeaderColumn label="Asignado a" />
                    <HeaderColumn label="Tipo asignación" />
                    <HeaderColumn label="Departamento" />
                    <HeaderColumn label="Estado" />
                    <HeaderColumn label="Proceso" />
                    <HeaderColumn label="Respuesta" />
                  </tr>
                </thead>
                <tbody>
                  {assignments?.map((assignment) => (
                    <tr key={assignment?.assignationCode}>
                      <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                        <a
                          href={assignment?.assignationFileUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={
                            assignment?.assignationFileUrl
                              ? "text-label-link underline"
                              : ""
                          }
                        >
                          {assignment?.assignationCode?.toUpperCase()}
                        </a>
                      </td>
                      <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                        {formatDate(assignment?.dispatchAt || "")}
                      </td>
                      <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                        {formatDate(assignment?.dueDateAt || "")}
                      </td>
                      <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                        <div className="flex flex-col font-bold text-center">
                          {typeof assignment?.dispatchBy === "object" &&
                          assignment?.dispatchBy !== null ? (
                            <>
                              <span>{assignment?.dispatchBy?.name || ""}</span>
                              <span className="font-normal">
                                {assignment?.dispatchBy?.lastName || ""}
                              </span>
                            </>
                          ) : (
                            <>
                              <span>{assignment?.dispatchBy || ""}</span>
                              <span className="font-normal"></span>
                            </>
                          )}
                        </div>
                      </td>
                      <td
                        className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-normal break-words max-w-xs"
                        style={{ minWidth: "300px" }}
                      >
                        {truncateText(assignment?.subject || "", 100)}
                      </td>
                      <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                        <div className="flex flex-col font-bold text-center">
                          {typeof assignment?.assignedTo === "object" &&
                          assignment?.assignedTo !== null ? (
                            <>
                              <span>{assignment?.assignedTo?.name || ""}</span>
                              <span className="font-normal">
                                {assignment?.assignedTo?.lastName || ""}
                              </span>
                            </>
                          ) : (
                            <>
                              <span>{assignment?.assignedTo || ""}</span>
                              <span className="font-normal"></span>
                            </>
                          )}
                        </div>
                      </td>
                      <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                        {assignment.manually
                          ? "Asignación Manual"
                          : "Asignación Email"}
                      </td>
                      <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                        {findDepartmentLabels(
                          [assignment?.departmentId],
                          departments
                        )}
                      </td>
                      <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                        <div
                          className={clsx(
                            "rounded-full px-3",
                            getClassByState(assignment?.status || ""),
                            "font-normal"
                          )}
                        >
                          {`${assignment?.status} ${
                            assignment?.status === "1" ? "día" : "días"
                          }`}
                        </div>
                      </td>
                      <td className="px-3 py-3 font-bold text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                        <div
                          className={clsx(
                            getClassByProcessStage(
                              assignment?.processStage || ""
                            )
                          )}
                        >
                          {getAssignmentProcessStage(
                            assignment?.processStage || ""
                          )}
                        </div>
                      </td>
                      <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                        {assignment?.assignationResponseFileUrl ? (
                          <a
                            href={assignment?.assignationResponseFileUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-label-link underline"
                          >
                            {assignment?.assignationResponseCode ||
                              "Sin respuesta"}
                          </a>
                        ) : (
                          "Sin respuesta"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )}

        {!noResultFound && (
          <div className="flex justify-center mt-4">
            <Pagination
              totalPages={totalPages}
              currentPage={currentPage}
              onSelectPage={setCurrentPage}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default TableAssignments;
