import React from "react";
import { Formik, Form } from "formik";
import SelectField from "../../../../../../../_emailApp/layout/components/SelectField";
import Button from "../../../../../../../_emailApp/layout/components/Button";

interface FilterModalReportProps {
  onSubmit: (values: { selectTypeReport: string }) => void;
  reportOptions: Array<{ value: string; label: string }>;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialValues: { selectTypeReport: string };
  onClearFilters: () => void;
}

const FilterModalReports: React.FC<FilterModalReportProps> = ({
  onSubmit,
  reportOptions,
  setIsModalOpen,
  initialValues,
  onClearFilters,
}) => {
  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setIsModalOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, []);

  return (
    <div
      className="absolute mt-2 w-72 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50"
      aria-labelledby="filter-modal"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          onSubmit(values);
          setIsModalOpen(false);
        }}
      >
        <Form className="flex flex-col pt-3 my-5 md:pt-4 mx-4 sm:mx-6">
          <h2 id="filter-modal" className="text-lg font-semibold mb-2">
            Filtros
          </h2>
          <SelectField
            label="Tipo de Reporte"
            placeholder="Selecciona una opción"
            name="selectTypeReport"
            options={reportOptions}
          />
          <div className="flex justify-end mt-4 gap-4">
            <Button
              type="button"
              color="primary"
              variant="outlined"
              onClick={onClearFilters}
            >
              Limpiar
            </Button>
            <Button type="submit" color="primary">
              Filtrar
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default FilterModalReports;
