import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../store";
import { fetchAPI } from "../../../../api/fetchAPI";
import { Department } from "../../../../../features/departments/types";
import { findDepartmentLabels } from "../../../../utils/findDepartmentLabels";
import { IoPencilSharp, IoTrashOutline } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import LogoUser from "../../../../../_emailApp/layout/components/LogoUser";
import Pagination from "../../../../../_emailApp/layout/components/Pagination";
import clsx from "clsx";
import NoResultFound from "../../../../../_emailApp/layout/components/NoResultFound";
import { NavbarDefault } from "../../../../../_emailApp/layout/components/navbar/NavbarDefault";
import UserHeader from "./components/UserHeader";
import { setRefreshUsers } from "../../../../../features/user/userSlice";
import { TOTAL_PAGES } from "../../../../constanst/constants";
import LoadingSpinner from "../../../../../_emailApp/layout/components/LoadingSpinner";
import { setError } from "../../../../../features/errors/errorSlice";
import { useRoles } from "../../../../hooks/useRoles";
import { BASE_API_URL } from "../../../../constanst/constantURL";

export interface User {
  userId: string;
  name: string;
  lastName: string;
  email: string;
  departmentIds: string[];
  roleId: string;
  background?: string;
  colorLabel?: string;
}

interface HeaderColumnProps {
  label?: string;
  className?: string;
}

const HeaderColumn: React.FC<HeaderColumnProps> = ({ label, className }) => {
  return (
    <th
      className={`px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-sm border-b-solid tracking-none whitespace-nowrap opacity-70 ${className}`}
    >
      {label}
    </th>
  );
};

const TableUser: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userId = useSelector(
    (state: RootState) => state.user.userDetails?.userId as string
  );
  const departments = useSelector(
    (state: RootState) => state.departments?.departments as Department[]
  );
  const refreshUsers = useSelector(
    (state: RootState) => state.user.refreshUsers
  );

  const { getTranslatedRoleOptions } = useRoles();
  const translatedRoles = getTranslatedRoleOptions();

  const [users, setUsers] = useState<User[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sizePages, setSizePages] = useState<number>(TOTAL_PAGES);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [searchParam, setSearchParam] = useState<string>("");
  const [selectRoleId, setSelectRoleId] = useState<string>("");
  const [selectDeparmentId, setSelectDeparmentId] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [noResultFound, setNoResultFound] = useState<boolean>(false);

  const fetchUsers = useCallback(
    async (page: number) => {
      setNoResultFound(false);
      try {
        const response = await fetchAPI<any>({
          url: `${BASE_API_URL}/user/search/page/${page - 1}/size/${sizePages}`,
          method: "POST",
          data: {
            searchParam: searchParam,
            roleId: selectRoleId,
            departmentId: selectDeparmentId,
          },
        });

        if (response.data) {
          setUsers(response.data.content);
          setTotalPages(response.data.totalPages);
          setSizePages(response.data.size);
          setLoading(false);
        } else {
          setNoResultFound(true);
        }
      } catch (error) {
        return;
      }
    },
    [sizePages, searchParam, selectRoleId, selectDeparmentId]
  );

  const handleDelete = async (idEditUser: string) => {
    try {
      const response = await fetchAPI({
        url: `${BASE_API_URL}/user/${idEditUser}`,
        method: "DELETE",
      });

      if (response.status === 200) {
        dispatch(
          setError({
            errorCode: 200,
            errorMessage: "El usuario fue eliminado con éxito",
          })
        );
      }

      if (response.status === 409) {
        dispatch(
          setError({
            errorCode: 409,
            errorMessage:
              "No se puede eliminar el usuario debido a un conflicto.",
          })
        );
      }

      dispatch(setRefreshUsers());
    } catch (error) {
      return;
    }
  };

  const handleDeleteList = async () => {
    try {
      const response = await fetchAPI({
        url: `${BASE_API_URL}/user/deleteList`,
        method: "DELETE",
        data: {
          usersToDelete: selectedUsers,
        },
      });

      if (response.status === 200) {
        dispatch(
          setError({
            errorCode: 200,
            errorMessage: "Los usuarios fueron eliminados con éxito",
          })
        );
      }

      if (response.status === 204) {
        dispatch(
          setError({
            errorCode: 29,
            errorMessage:
              "No se puedo eliminar los usuarios debido a un conflicto.",
          })
        );
      }

      if (response.status === 409) {
        dispatch(
          setError({
            errorCode: 409,
            errorMessage:
              "No se puede eliminar el usuario debido a un conflicto.",
          })
        );
      }

      dispatch(setRefreshUsers());
      setSelectedUsers([]);
    } catch (error) {
      return;
    }
  };

  const handleEditUser = (user?: User) => {
    if (user) {
      navigate("/dashboard/users/create-user", { state: { user } });
    } else {
      navigate("/dashboard/users/create-user");
    }
  };

  const handleCheckboxChange = (userId: string, isChecked: boolean) => {
    setSelectedUsers((prevSelectedUsers) => {
      if (isChecked) {
        return [...prevSelectedUsers, userId];
      } else {
        return prevSelectedUsers.filter((id) => id !== userId);
      }
    });
  };

  const getRoleLabel = (roleId: string) => {
    const role = translatedRoles.find((r) => r.value === roleId);
    return role ? role.label : "Desconocido";
  };

  useEffect(() => {
    fetchUsers(currentPage);
  }, [fetchUsers, currentPage, refreshUsers]);

  return (
    <div className="p-4 sm:ml-64 mt-14">
      <NavbarDefault title="Usuarios" />
      <UserHeader
        onSearchChange={setSearchParam}
        onDeleteList={handleDeleteList}
        onToggleFilterModal={() => setIsModalOpen(!isModalOpen)}
        onAddUser={() => handleEditUser()}
        setSelectRoleId={setSelectRoleId}
        setSelectDeparmentId={setSelectDeparmentId}
      />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="overflow-x-auto">
          {noResultFound ? (
            <div>
              <NoResultFound />
            </div>
          ) : (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="align-bottom">
                <tr>
                  <HeaderColumn label="" />
                  <HeaderColumn label="" />
                  <HeaderColumn label={t("user.NAME")} />
                  <HeaderColumn label={t("user.LAST_NAME")} />
                  <HeaderColumn label={t("user.EMAIL")} />
                  <HeaderColumn label={t("user.ROL")} />
                  <HeaderColumn label={t("user.DEPARTMENTS")} />
                  <HeaderColumn label={t("user.ACTIONS")} />
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {users?.map((user) => (
                  <tr
                    className={clsx({
                      "bg-primary-100": !!selectedUsers.includes(user.userId),
                    })}
                    key={user.userId}
                  >
                    <td className="px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-placeholder shadow-none text-sm border-b-solid tracking-none whitespace-nowrap opacity-70">
                      {user.userId === userId ? (
                        ""
                      ) : (
                        <input
                          type="checkbox"
                          className="scale-150"
                          checked={selectedUsers.includes(user.userId)}
                          onChange={(e) =>
                            handleCheckboxChange(user.userId, e.target.checked)
                          }
                        />
                      )}
                    </td>

                    <td className="p-2 align-middle bg-transparent border-b whitespace-nowrap shadow-transparent">
                      <LogoUser
                        name={user.name}
                        lastName={user.lastName}
                        size={40}
                      />
                    </td>
                    <td className="p-2 align-middle bg-transparent border-b break-words max-w-xs shadow-transparent">
                      <p className="mb-0 font-medium text-center leading-tight text-sm text-label-primary">
                        {user.name}
                      </p>
                    </td>
                    <td className="p-2 align-middle bg-transparent border-b break-words max-w-xs shadow-transparent">
                      <p className="mb-0 font-medium text-center leading-tight text-sm text-label-primary">
                        {user.lastName}
                      </p>
                    </td>
                    <td className="p-2 text-center align-middle bg-transparent border-b break-words max-w-xs shadow-transparent">
                      <p className="mb-0 font-medium text-center leading-tight text-sm text-label-primary">
                        {user.email}
                      </p>
                    </td>
                    <td className="p-2 text-center align-middle bg-transparent border-b break-words max-w-xs shadow-transparent">
                      <p className="mb-0 font-medium text-center leading-tight text-sm text-label-primary">
                        {getRoleLabel(user?.roleId)}
                      </p>
                    </td>
                    <td className="p-2 text-center align-middle bg-transparent border-b break-words max-w-xs shadow-transparent">
                      <p className="mb-0 font-medium text-center leading-tight text-sm text-label-primary">
                        {findDepartmentLabels(user?.departmentIds, departments)}
                      </p>
                    </td>
                    <td className="p-2 text-center align-middle bg-transparent border-b break-words max-w-xs shadow-transparent">
                      <div className="flex justify-evenly">
                        <div
                          className="flex"
                          onClick={() => handleEditUser(user)}
                        >
                          <IoPencilSharp className="mr-5" />
                        </div>
                        <div
                          className="flex"
                          onClick={() => handleDelete(user.userId)}
                        >
                          <IoTrashOutline />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
      {!noResultFound && (
        <div className="flex justify-center mt-4">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onSelectPage={setCurrentPage}
          />
        </div>
      )}
    </div>
  );
};

export default TableUser;
