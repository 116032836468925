import React, { useEffect, useRef, useState } from "react";
import Button from "../../../../../../_emailApp/layout/components/Button";
import { IoAddOutline, IoFilterOutline } from "react-icons/io5";
import FilterModal from "./FilterModal";
import useDepartmentOptions from "../../../../../hooks/useDepartmentOptions";
import TextField from "../../../../../../_emailApp/layout/components/TextField";
import { Form, Formik } from "formik";

interface InvoiceHeaderProps {
  onSearchChange: React.Dispatch<React.SetStateAction<string>>;
  onAddInvoice?: () => void;
  setSelectRoleId?: React.Dispatch<React.SetStateAction<string>>;
  setSelectDeparmentId?: React.Dispatch<React.SetStateAction<string>>;
  onToggleFilterModal?: () => void;
}

const initialValues = {
  invoiceIssueDate: "",
};

const InvoiceHeader: React.FC<InvoiceHeaderProps> = ({
  onSearchChange,
  onAddInvoice,
  setSelectRoleId,
  setSelectDeparmentId,
  onToggleFilterModal,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const departmentOptions = useDepartmentOptions();
  const modalRef = useRef<HTMLDivElement>(null);

  const [selectedRoleId, setSelectedRoleId] = useState<string>("");
  const [selectedDepartmentId, setSelectedDepartmentId] = useState<string>("");

  const hasFilters = selectedRoleId !== "" || selectedDepartmentId !== "";

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFiltersSubmit = (values: {
    selectRoleId: string;
    selectDepartmentId: string;
  }) => {
    setSelectedRoleId(values.selectRoleId);
    setSelectedDepartmentId(values.selectDepartmentId);
  };

  const handleClearFilters = () => {
    setSelectedRoleId("");
    setSelectedDepartmentId("");

    setIsModalOpen(false);
  };

  return (
    <div className="mb-8">
      <div className="flex justify-between text-secondary mb-4 relative flex-col md:flex-row">
        <input
          type="text"
          onChange={(e) => onSearchChange(e.target.value)}
          placeholder="Buscar por radicado, factura, proyecto"
          id="searchUser"
          className="w-[300px] h-[38px] focus:shadow-soft-primary-outline text-sm leading-5.6 ease-soft block appearance-none rounded-lg border border-solid border-background bg-background bg-clip-padding px-5 py-2 font-medium text-label-secondary transition-all focus:border-primary focus:outline-none focus:transition-shadow focus:bg-primary-100 ml-3 mb-4 md:mb-0"
        />

        <div className="flex flex-col md:flex-row items-start md:items-center">
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
              console.log(values);
            }}
          >
            {({ values }) => (
              <Form>
                <div className="flex">
                  <TextField
                    name="invoiceIssueDate"
                    placeholder="Fecha inicio"
                    type="date"
                    requested
                  />
                  <div className="ml-[30px]">
                    <TextField
                      name="invoiceIssueDate"
                      placeholder="Fecha fin"
                      type="date"
                      requested
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <div className="ml-0 md:ml-6 mb-4 md:mb-0" ref={modalRef}>
            <div className="relative inline-block">
              <Button
                onClick={() => {
                  setIsModalOpen(!isModalOpen);
                  onToggleFilterModal && onToggleFilterModal();
                }}
                type="submit"
                className="md:ml-6"
                color={hasFilters ? "primary" : "cancel"}
                size="medium"
                width={100}
                startIcon={<IoFilterOutline size="1.5em" />}
              >
                Filtros
              </Button>
            </div>
            {isModalOpen && (
              <FilterModal
                onSubmit={handleFiltersSubmit}
                departmentOptions={departmentOptions}
                setIsModalOpen={setIsModalOpen}
                initialValues={{
                  selectRoleId: selectedRoleId,
                  selectDepartmentId: selectedDepartmentId,
                }}
                onClearFilters={handleClearFilters}
              />
            )}
          </div>

          <Button
            onClick={onAddInvoice}
            type="submit"
            className="ml-0 md:ml-6"
            color="primary"
            size="medium"
            width={180}
            startIcon={<IoAddOutline size="1.5em" />}
          >
            Nueva Factura
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InvoiceHeader;
