import React from "react";
import LineChart from "../../../../../../../_emailApp/layout/components/LineChart";
import { monthlyInvoice, pendingInvoices } from "../data";

const InvoicesDashboard: React.FC = () => {
  const lineData = {
    labels: monthlyInvoice.map((data) => data.month),
    datasets: [
      {
        label: "Monto Total Pendiente",
        data: [5000, 6000, 4500, 8000, 7500, 4000],
        borderColor: "#F59E0B",
        backgroundColor: "#F59E0B",
        fill: false,
        tension: 0.4,
        pointRadius: 2,
        borderWidth: 2,
      },
      {
        label: "Monto Total Pagado",
        data: [3000, 4000, 5000, 7000, 6000, 5500],
        borderColor: "#10B981",
        backgroundColor: "#10B981",
        fill: false,
        tension: 0.4,
        pointRadius: 2,
        borderWidth: 2,
      },
    ],
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div className="bg-white shadow-md rounded-lg p-6 lg:col-span-2 border border-solid border-menu-button">
        <h2 className="text-md font-semibold text-gray-700 mb-4">
          Facturas Pendientes
        </h2>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Radicado</th>
              <th className="py-2 px-4 border-b">Proveedor</th>
              <th className="py-2 px-4 border-b">Fecha de Vencimiento</th>
              <th className="py-2 px-4 border-b">Monto Total</th>
            </tr>
          </thead>
          <tbody>
            {pendingInvoices.map((invoice) => (
              <tr key={invoice.radicado}>
                <td className="py-2 px-4 border-b text-center">
                  {invoice.radicado}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {invoice.proveedor}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  {invoice.fechaVencimiento}
                </td>
                <td className="py-2 px-4 border-b text-center">
                  ${invoice.montoTotal}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 lg:col-span-2 border border-solid border-menu-button">
        <h2 className="text-md font-semibold text-gray-700 mb-4">
          Evolución del Monto Total de Facturas
        </h2>
        <LineChart data={lineData} />
      </div>
    </div>
  );
};

export default InvoicesDashboard;
