import React from "react";
import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";

ChartJS.register(ArcElement, Tooltip, Legend);

interface DoughnutChartProps {
  data: ChartData<"doughnut">;
  options?: ChartOptions<"doughnut">;
}

const defaultOptions: ChartOptions<"doughnut"> = {
  cutout: "70%",
  maintainAspectRatio: true,
  aspectRatio: 1.5,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        padding: 20,
        font: {
          size: 14,
        },
        color: "#4B5563",
      },
    },
    tooltip: {
      backgroundColor: "#FFFFFF",
      bodyColor: "#1F2937",
      borderColor: "#E5E7EB",
      borderWidth: 1,
      cornerRadius: 5,
      padding: 10,
      titleColor: "#111827",
      titleFont: {
        size: 14,
      },
      bodyFont: {
        size: 12,
      },
    },
  },
  animation: {
    animateScale: true,
    animateRotate: true,
  },
  layout: {
    padding: 10,
  },
};

const DoughnutChart: React.FC<DoughnutChartProps> = ({ data, options }) => {
  const mergedOptions: ChartOptions<"doughnut"> = {
    ...defaultOptions,
    ...options,
    plugins: {
      ...defaultOptions.plugins,
      ...options?.plugins,
      legend: {
        ...defaultOptions.plugins?.legend,
        ...options?.plugins?.legend,
      },
      tooltip: {
        ...defaultOptions.plugins?.tooltip,
        ...options?.plugins?.tooltip,
      },
    },
  };

  return <Doughnut data={data} options={mergedOptions} />;
};

export default DoughnutChart;
