import React, { useEffect, useRef, useState } from "react";
import {
  totalByProvider,
  monthlyTotalAmounts,
  totalAmountsByProcess,
  monthlyProcessTotals,
  monthlyProjectTotals,
  totalByProject,
  monthlyProcessTotalsByProject,
} from "../data";
import LineChart from "../../../../../../../_emailApp/layout/components/LineChart";
import Button from "../../../../../../../_emailApp/layout/components/Button";
import { IoFilterOutline } from "react-icons/io5";
import FilterModalReports from "./FilterModalReports";
import { Form, Formik } from "formik";
import TextField from "../../../../../../../_emailApp/layout/components/TextField";

const ReportOptions = [
  {
    label: "Reportes por proyecto",
    value: "true",
  },
  {
    label: "Reportes por proveedor",
    value: "falso",
  },
];

const initialValues = {
  startDate: "",
  endDate: "",
};

const providerTotalChartData = {
  labels: totalByProvider.map((data) => data.name),
  datasets: [
    {
      label: "Monto Total Proveedor",
      data: totalByProvider.map((data) => data.total),
      borderColor: "#2691F5",
      backgroundColor: "#2691F5",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
  ],
};

const monthlyTotalChartData = {
  labels: monthlyTotalAmounts.map((data) => data.month),
  datasets: [
    {
      label: "Monto Total Mensual",
      data: monthlyTotalAmounts.map((data) => data.total),
      borderColor: "#2691F5",
      backgroundColor: "#2691F5",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
  ],
};

const processTotalChartData = {
  labels: totalAmountsByProcess.map((data) => data.name),
  datasets: [
    {
      label: "Recibidas",
      data: totalAmountsByProcess.map((data) => data.received),
      borderColor: "#2691F5",
      backgroundColor: "#2691F5",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
    {
      label: "Revisadas",
      data: totalAmountsByProcess.map((data) => data.reviewed),
      borderColor: "#FFA800",
      backgroundColor: "#FFA800",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
    {
      label: "En Proceso",
      data: totalAmountsByProcess.map((data) => data.inProcess),
      borderColor: "#F64E60",
      backgroundColor: "#F64E60",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
    {
      label: "Finalizadas",
      data: totalAmountsByProcess.map((data) => data.finalized),
      borderColor: "#11B9B2",
      backgroundColor: "#11B9B2",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
  ],
};

const monthlyProcessTotalChartData = {
  labels: monthlyProcessTotals.map((data) => data.month),
  datasets: [
    {
      label: "Recibidas",
      data: monthlyProcessTotals.map((data) => data.received),
      borderColor: "#2691F5",
      backgroundColor: "#2691F5",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
    {
      label: "Revisadas",
      data: monthlyProcessTotals.map((data) => data.reviewed),
      borderColor: "#FFA800",
      backgroundColor: "#FFA800",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
    {
      label: "En Proceso",
      data: monthlyProcessTotals.map((data) => data.inProcess),
      borderColor: "#F64E60",
      backgroundColor: "#F64E60",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
    {
      label: "Finalizadas",
      data: monthlyProcessTotals.map((data) => data.finalized),
      borderColor: "#11B9B2",
      backgroundColor: "#11B9B2",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
  ],
};

const projectTotalChartData = {
  labels: totalByProject.map((data) => data.name),
  datasets: [
    {
      label: "Monto Total Proyecto",
      data: totalByProject.map((data) => data.total),
      borderColor: "#2691F5",
      backgroundColor: "#2691F5",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
  ],
};

const monthlyProjectTotalChartData = {
  labels: monthlyProjectTotals.map((data) => data.month),
  datasets: [
    {
      label: "Monto Total Mensual Proyecto",
      data: monthlyProjectTotals.map((data) => data.total),
      borderColor: "#2691F5",
      backgroundColor: "#2691F5",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
  ],
};

const monthlyProcessTotalProjectChartData = {
  labels: monthlyProcessTotalsByProject.map((data) => data.month),
  datasets: [
    {
      label: "Recibidas",
      data: monthlyProcessTotalsByProject.map((data) => data.received),
      borderColor: "#2691F5",
      backgroundColor: "#2691F5",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
    {
      label: "Revisadas",
      data: monthlyProcessTotalsByProject.map((data) => data.reviewed),
      borderColor: "#FFA800",
      backgroundColor: "#FFA800",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
    {
      label: "En Proceso",
      data: monthlyProcessTotalsByProject.map((data) => data.inProcess),
      borderColor: "#F64E60",
      backgroundColor: "#F64E60",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
    {
      label: "Finalizadas",
      data: monthlyProcessTotalsByProject.map((data) => data.finalized),
      borderColor: "#11B9B2",
      backgroundColor: "#11B9B2",
      fill: false,
      tension: 0.4,
      pointRadius: 2,
      borderWidth: 2,
    },
  ],
};

const FinancialAnalysis: React.FC = () => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectTypeReport, setSelectTypeReport] = useState<string | null>(null);

  const hasFilters = selectTypeReport !== null && selectTypeReport !== "";

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      setIsModalOpen(false);
    }
  };

  const handleFiltersSubmit = (values: { selectTypeReport: string }) => {
    setSelectTypeReport(values.selectTypeReport);
  };

  const handleClearFilters = () => {
    setSelectTypeReport(null);
    setIsModalOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="grid grid-cols-1 gap-6">
      <div className="bg-white shadow-md rounded-lg p-6 lg:col-span-2 border border-solid border-menu-button">
        <h2 className="text-md font-semibold mb-4">Monto Total Proveedor</h2>
        <LineChart data={providerTotalChartData} />
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 lg:col-span-2 border border-solid border-menu-button">
        <h2 className="text-md font-semibold mb-4">
          Monto Total Proveedor Por Mes
        </h2>
        <LineChart data={monthlyTotalChartData} />
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 lg:col-span-2 border border-solid border-menu-button">
        <h2 className="text-md font-semibold mb-4">
          Monto Total Proveedor Proceso
        </h2>
        <LineChart data={processTotalChartData} />
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 lg:col-span-2 border border-solid border-menu-button">
        <h2 className="text-md font-semibold mb-4">
          Monto Total Proveedor Proceso Por Mes
        </h2>
        <LineChart data={monthlyProcessTotalChartData} />
      </div>

      <div className="bg-white shadow-md rounded-lg p-6 lg:col-span-2 border border-solid border-menu-button">
        <h2 className="text-md font-semibold mb-4">Monto Total Proyecto</h2>
        <LineChart data={projectTotalChartData} />
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 lg:col-span-2 border border-solid border-menu-button">
        <h2 className="text-md font-semibold mb-4">
          Monto Total Proyecto Por Mes
        </h2>
        <LineChart data={monthlyProjectTotalChartData} />
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 lg:col-span-2 border border-solid border-menu-button">
        <h2 className="text-md font-semibold mb-4">
          Monto Total Proyecto Proceso
        </h2>
        <LineChart data={monthlyProcessTotalProjectChartData} />
      </div>
      <div className="bg-white shadow-md rounded-lg p-6 lg:col-span-2 border border-solid border-menu-button">
        <h2 className="text-md font-semibold mb-4">
          Monto Total Proyecto Por Mes
        </h2>
        <LineChart data={monthlyProcessTotalProjectChartData} />
      </div>
    </div>
  );
};

export default FinancialAnalysis;
