import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchAPI } from "../../../../api/fetchAPI";
import Pagination from "../../../../../_emailApp/layout/components/Pagination";
import NoResultFound from "../../../../../_emailApp/layout/components/NoResultFound";
import { NavbarDefault } from "../../../../../_emailApp/layout/components/navbar/NavbarDefault";
import InvoiceHeader from "./components/InvoiceHeader";
import { formatCurrency } from "../../../../utils/formatCurrency";
import {
  STATUS_DESCRIPTIONS_INVOICE,
  STATUS_PROCESSSTAGE,
  TOTAL_PAGES,
} from "../../../../constanst/constants";
import LoadingSpinner from "../../../../../_emailApp/layout/components/LoadingSpinner";
import InvoiceDetailsModal from "./board/components/InvoiceDetailsModal";
import { BASE_API_URL } from "../../../../constanst/constantURL";

export interface Invoice {
  project: string;
  invoiceId: string;
  trackingNumber: string;
  invoiceNumber: string;
  invoiceIssueDate: string;
  invoiceDueDate: string;
  invoiceUrl: string;
  vendorName: string;
  vendorNit: string;
  vendorAddress: string;
  vendorPhone: string;
  taxAmount: number;
  totalAmount: number;
  status: string;
  processStage: string;
  createAt: string;
  assignedTo: {
    userId: string;
    name: string;
    lastName: string;
  };
  reviewer: {
    userId: string;
    name: string;
    lastName: string;
  };
  details: {
    item: string;
    total: string;
    quantity: string;
    price: string;
  }[];
}

interface HeaderColumnProps {
  label?: string;
  className?: string;
}

const HeaderColumn: React.FC<HeaderColumnProps> = ({ label, className }) => {
  return (
    <th
      className={`px-6 py-3 font-bold text-center uppercase align-middle bg-transparent border-b border-gray-200 shadow-none text-sm border-b-solid tracking-none whitespace-nowrap opacity-70 ${className}`}
    >
      {label}
    </th>
  );
};

const InvoicesTable: React.FC = () => {
  const navigate = useNavigate();

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sizePages, setSizePages] = useState<number>(TOTAL_PAGES);
  const [loading, setLoading] = useState<boolean>(true);
  const [noResultFound, setNoResultFound] = useState<boolean>(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [selectedDetails, setSelectedDetails] = useState<any[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectRoleId, setSelectRoleId] = useState<string>("");
  const [selectDeparmentId, setSelectDeparmentId] = useState<string>("");
  const [searchParam, setSearchParam] = useState<string>("");

  const fetchInvoices = useCallback(
    async (page: number) => {
      setNoResultFound(false);
      setLoading(true);
      try {
        const response = await fetchAPI<any>({
          url: `${BASE_API_URL}/expense/page/${page - 1}/size/${sizePages}`,
          method: "GET",
        });

        if (response.data) {
          setInvoices(response.data.content);
          setTotalPages(response.data.totalPages);
          setSizePages(response.data.size);
        } else {
          setNoResultFound(true);
        }
      } catch (error) {
        return;
      } finally {
        setLoading(false);
      }
    },
    [sizePages]
  );

  const handleEditInvoice = (invoice?: Invoice) => {
    if (invoice) {
      navigate("/dashboard/invoices/table-invoices/invoices-pdf", {
        state: { invoice },
      });
    } else {
      navigate("/dashboard/invoices/table-invoices/invoices-file");
    }
  };

  const handleShowDetails = (details: any[]) => {
    setSelectedDetails(details);
    setIsDetailsModalOpen(true);
  };

  useEffect(() => {
    fetchInvoices(currentPage);
  }, [fetchInvoices, currentPage]);

  return (
    <div className="p-4 sm:ml-64 mt-14">
      <NavbarDefault title="Facturas" />
      <InvoiceHeader
        onSearchChange={setSearchParam}
        onAddInvoice={() => handleEditInvoice()}
        onToggleFilterModal={() => setIsModalOpen(!isModalOpen)}
        setSelectRoleId={setSelectRoleId}
        setSelectDeparmentId={setSelectDeparmentId}
      />
      <div className="overflow-x-auto">
        {loading ? (
          <div className="flex justify-center py-10">
            <LoadingSpinner />
          </div>
        ) : noResultFound ? (
          <NoResultFound />
        ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="align-bottom">
              <tr>
                <HeaderColumn label="Radicado" />
                <HeaderColumn label="Número" />
                <HeaderColumn label="Proyecto" />
                <HeaderColumn label="Fecha de Emisión" />
                <HeaderColumn label="Fecha de Vencimiento" />
                <HeaderColumn label="Nombre del Proveedor" />
                <HeaderColumn label="NIT del Proveedor" />
                <HeaderColumn label="Monto de Impuestos" />
                <HeaderColumn label="Monto Total" />
                <HeaderColumn label="Detalles" />
                <HeaderColumn label="Estado de proceso" />
                <HeaderColumn label="Estado de la factura" />
                <HeaderColumn label="Fecha de Creación" />
                <HeaderColumn label="Asignado a" />
                <HeaderColumn label="Revisado por" />
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {invoices.map((invoice) => (
                <tr key={invoice?.invoiceId} className="bg-white">
                  <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                    <a
                      href={invoice?.invoiceUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={
                        invoice?.invoiceUrl ? "text-label-link underline" : ""
                      }
                    >
                      {invoice?.trackingNumber?.toUpperCase()}
                    </a>
                  </td>
                  <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                    {invoice?.invoiceNumber?.toUpperCase()}
                  </td>
                  <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                    {invoice?.project}
                  </td>
                  <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                    {invoice?.invoiceIssueDate || "-"}
                  </td>
                  <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                    {invoice?.invoiceDueDate || "-"}
                  </td>
                  <td className="px-3 py-3 text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap font-bold">
                    {invoice?.vendorName || "-"}
                  </td>
                  <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                    {invoice?.vendorNit || "-"}
                  </td>
                  <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                    {formatCurrency(invoice?.taxAmount) || "-"}
                  </td>
                  <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                    {formatCurrency(invoice?.totalAmount) || "-"}
                  </td>
                  <td
                    className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap cursor-pointer text-blue-500 underline"
                    onClick={() => handleShowDetails(invoice?.details)}
                  >
                    Ver detalles
                  </td>
                  <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                    {STATUS_DESCRIPTIONS_INVOICE[invoice?.processStage] ||
                      invoice?.processStage ||
                      "Sin estado"}
                  </td>
                  <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                    {STATUS_PROCESSSTAGE[invoice?.status]}
                  </td>
                  <td className="px-3 py-3 font-normal text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap">
                    {invoice?.createAt || "-"}
                  </td>
                  <td className="px-3 py-3 text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap font-bold">
                    {`${invoice?.assignedTo?.name} ${invoice?.assignedTo?.lastName}`}
                  </td>
                  <td className="px-3 py-3 text-center text-sm align-middle bg-transparent border-b border-placeholder shadow-none border-b-solid tracking-none whitespace-nowrap font-bold">
                    {invoice?.reviewer?.name && invoice?.reviewer?.lastName
                      ? `${invoice?.reviewer?.name} ${invoice?.reviewer?.lastName}`
                      : "Sin revisor"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {!noResultFound && !loading && (
        <div className="flex justify-center mt-4">
          <Pagination
            totalPages={totalPages}
            currentPage={currentPage}
            onSelectPage={setCurrentPage}
          />
        </div>
      )}
      <InvoiceDetailsModal
        isOpen={isDetailsModalOpen}
        onClose={() => setIsDetailsModalOpen(false)}
        details={selectedDetails}
      />
    </div>
  );
};

export default InvoicesTable;
