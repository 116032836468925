import React from "react";
import PolarAreaChart from "../../../../../../../_emailApp/layout/components/PolarAreaChart";
import BarChart from "../../../../../../../_emailApp/layout/components/BarChart";
import LineChart from "../../../../../../../_emailApp/layout/components/LineChart";

const assignmentsData = [
  { person: "Juan", count: 40 },
  { person: "Ana", count: 30 },
  { person: "Carlos", count: 50 },
  { person: "Maria", count: 60 },
  { person: "Pedro", count: 20 },
];

const statusData = [
  {
    person: "Juan",
    statuses: { paid: 10, pending: 5, inReview: 3, inProcess: 2 },
  },
  {
    person: "Ana",
    statuses: { paid: 7, pending: 4, inReview: 2, inProcess: 2 },
  },
  {
    person: "Carlos",
    statuses: { paid: 6, pending: 3, inReview: 4, inProcess: 1 },
  },
  {
    person: "Maria",
    statuses: { paid: 8, pending: 6, inReview: 2, inProcess: 2 },
  },
];

const monthlyAssignments = [
  { month: "January", Juan: 5, Ana: 4, Carlos: 2, Maria: 3 },
  { month: "February", Juan: 3, Ana: 3, Carlos: 4, Maria: 2 },
  { month: "March", Juan: 4, Ana: 5, Carlos: 3, Maria: 5 },
  { month: "April", Juan: 2, Ana: 3, Carlos: 2, Maria: 4 },
];

const COLORS = ["#2691F5", "#FFA800", "#F64E60", "#11B9B2"];

const AssignmentsReport: React.FC = () => {
  const polarData = {
    labels: assignmentsData.map((data) => data.person),
    datasets: [
      {
        label: "Cantidad de Facturas",
        data: assignmentsData.map((data) => data.count),
        backgroundColor: COLORS,
        borderWidth: 1,
      },
    ],
  };

  const stackedBarData = {
    labels: statusData.map((data) => data.person),
    datasets: [
      {
        label: "Resivido",
        data: statusData.map((data) => data.statuses.paid),
        backgroundColor: COLORS[0],
      },
      {
        label: "En Revisión",
        data: statusData.map((data) => data.statuses.pending),
        backgroundColor: COLORS[1],
      },
      {
        label: "En Proceso de pago",
        data: statusData.map((data) => data.statuses.inReview),
        backgroundColor: COLORS[2],
      },
      {
        label: "Finalizado",
        data: statusData.map((data) => data.statuses.inProcess),
        backgroundColor: COLORS[3],
      },
    ],
  };

  const lineData = {
    labels: monthlyAssignments.map((data) => data.month),
    datasets: [
      {
        label: "Juan",
        data: monthlyAssignments.map((data) => data.Juan),
        borderColor: COLORS[0],
        backgroundColor: COLORS[0],
        fill: false,
        tension: 0.4,
        pointRadius: 2,
        borderWidth: 2,
      },
      {
        label: "Ana",
        data: monthlyAssignments.map((data) => data.Ana),
        borderColor: COLORS[1],
        backgroundColor: COLORS[1],
        fill: false,
        tension: 0.4,
        pointRadius: 2,
        borderWidth: 2,
      },
      {
        label: "Carlos",
        data: monthlyAssignments.map((data) => data.Carlos),
        borderColor: COLORS[2],
        backgroundColor: COLORS[2],
        fill: false,
        tension: 0.4,
        pointRadius: 2,
        borderWidth: 2,
      },
      {
        label: "Maria",
        data: monthlyAssignments.map((data) => data.Maria),
        borderColor: COLORS[3],
        backgroundColor: COLORS[3],
        fill: false,
        tension: 0.4,
        pointRadius: 2,
        borderWidth: 2,
      },
    ],
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div className="bg-white shadow-md rounded-lg p-6 border border-solid border-menu-button">
        <h2 className="text-md font-semibold text-gray-700 mb-4">
          Cantidad de Facturas por Persona
        </h2>
        <PolarAreaChart data={polarData} />
      </div>

      <div className="bg-white shadow-md rounded-lg p-6 border border-solid border-menu-button">
        <h2 className="text-md font-semibold text-gray-700 mb-4">
          Distribución de Estados de Facturas por Persona
        </h2>
        <BarChart data={stackedBarData} />
      </div>

      <div className="bg-white shadow-md rounded-lg p-6 lg:col-span-2 border border-solid border-menu-button">
        <h2 className="text-md font-semibold text-gray-700 mb-4">
          Evolución de Asignaciones de Facturas
        </h2>
        <LineChart data={lineData} />
      </div>
    </div>
  );
};

export default AssignmentsReport;
