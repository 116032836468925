import React, { useState } from "react";
import { NavbarDefault } from "../../../../../../_emailApp/layout/components/navbar/NavbarDefault";
import { Tabs } from "../../../../../../_emailApp/layout/components/Tabs";
import {
  IoClipboardOutline,
  IoDocumentTextOutline,
  IoPieChartOutline,
  IoStatsChart,
} from "react-icons/io5";
import AssignmentsReport from "./components/AssignmentsReport";
import FinancialAnalysis from "./components/FinancialAnalysis";
import InvoicesDashboard from "./components/InvoicesDashboard";
import ProgressCharts from "./components/ProgressCharts";

const tabOptions = [
  { name: "Gráficas de Progreso", icon: IoStatsChart },
  { name: "Panel de Facturas", icon: IoDocumentTextOutline },
  { name: "Análisis Financiero", icon: IoPieChartOutline },
  { name: "Informe de Asignaciones", icon: IoClipboardOutline },
];

export const ReportInvoice = () => {
  const [selectedTab, setSelectedTab] = useState<string>(tabOptions[0].name);

  return (
    <div className="p-4 sm:ml-64 mt-14">
      <div className="flex flex-col">
        <NavbarDefault title="Reporte de facturas" />
      </div>

      <div className="mb-4">
        <Tabs
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          tabs={tabOptions}
        />
      </div>
      <div className="mx-5">
        {selectedTab === "Gráficas de Progreso" && <ProgressCharts />}
        {selectedTab === "Panel de Facturas" && <InvoicesDashboard />}
        {selectedTab === "Análisis Financiero" && <FinancialAnalysis />}
        {selectedTab === "Informe de Asignaciones" && <AssignmentsReport />}
      </div>
    </div>
  );
};
