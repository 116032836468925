import React from "react";

interface TypographyProps {
  fontSize?:
    | "text-xs"
    | "text-sm"
    | "text-base"
    | "text-lg"
    | "text-md"
    | "text-2xl"
    | "text-3xl"
    | "text-4xl";
  fontFamily?: string;
  fontWeight?:
    | "normal"
    | "bold"
    | "bolder"
    | "lighter"
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "800";
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span";
  textDecoration?: "none" | "underline" | "overline" | "line-through";
  color?: string;
  textAlign?: "left" | "right" | "center" | "justify";
  display?: "initial" | "block" | "inline";
  className?: string;
  children?: React.ReactNode;
}

const Typography: React.FC<TypographyProps> = ({
  fontSize = "text-sm",
  fontFamily = "Inter",
  fontWeight = "normal",
  as: Component = "p",
  textDecoration = "none",
  color = "text-label-primary",
  textAlign = "left",
  display = "flex",
  className = "",
  children,
}) => {
  const style = {
    fontSize,
    fontFamily,
    fontWeight,
    textDecoration,
    color,
    textAlign,
    display,
    alignItems: "left",
    justifyContent: "left",
  };
  return (
    <Component style={style} className={`${fontSize} ${className} ${color}`}>
      {children}
    </Component>
  );
};

export default Typography;
