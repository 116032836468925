import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
  ChartOptions,
  ChartData,
} from "chart.js";

ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

interface LineChartProps {
  data: ChartData<"line">;
  options?: ChartOptions<"line">;
}

const defaultOptions: ChartOptions<"line"> = {
  responsive: true,
  maintainAspectRatio: true,
  aspectRatio: 2.5,
  plugins: {
    legend: {
      position: "bottom",
      labels: {
        padding: 20,
        font: {
          size: 14,
        },
        color: "#4B5563",
      },
    },
    tooltip: {
      backgroundColor: "#FFFFFF",
      bodyColor: "#1F2937",
      borderColor: "#E5E7EB",
      borderWidth: 1,
      cornerRadius: 5,
      padding: 10,
      titleColor: "#111827",
      titleFont: {
        size: 14,
      },
      bodyFont: {
        size: 12,
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: "#6B7280",
        font: {
          size: 12,
        },
      },
    },
    y: {
      grid: {
        color: "#E5E7EB",
      },
      ticks: {
        color: "#6B7280",
        font: {
          size: 12,
        },
      },
    },
  },
};

const LineChart: React.FC<LineChartProps> = ({ data, options }) => {
  const mergedOptions: ChartOptions<"line"> = {
    ...defaultOptions,
    ...options,
    plugins: {
      ...defaultOptions.plugins,
      ...options?.plugins,
      legend: {
        ...defaultOptions.plugins?.legend,
        ...options?.plugins?.legend,
      },
      tooltip: {
        ...defaultOptions.plugins?.tooltip,
        ...options?.plugins?.tooltip,
      },
    },
  };

  return <Line data={data} options={mergedOptions} />;
};

export default LineChart;
